import { User } from 'cde-fe-organization-registration-dialog';
import { AuthServiceWrapper } from './../../services/auth.service';
import { map, tap, exhaustMap, finalize, catchError, take } from 'rxjs/operators';
import * as RootState from '..';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as AuthActions from './auth.actions';
import { of } from 'rxjs';
import { IAuthenticationResponse } from '@app/shared/models/auth.model';
import { MaintenanceTextService } from '@app/shared/services/maintenance-text.service';
import { SetOrganisationChanging } from '../organisations/organisations.actions';
import { RehydrateState } from '../organisations/organisations.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LOGIN),
        tap(() => {
          this.auth.login();
        })
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN_REDIRECT),
      map(() => {
        // //this.router.navigate(['/login']);
        new AuthActions.LoginRedirectSuccess();
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LOGIN_SUCCESS),
        map((action: AuthActions.LoginSuccess) => action.payload),
        tap((payload: IAuthenticationResponse | undefined) => {
          // Set is social user after login from id token...
          this.store.dispatch(new AuthActions.SetIsSocialUser(this.auth.getIsSocialFromIdToken()));

          let url: string | null;
          if ((url = localStorage.getItem('redirectUrl'))) {
            localStorage.removeItem('redirectUrl');
            void this.router.navigate([url]);
          } else {
            void this.router.navigate(['']);
          }

          // Rehydrate when login is complete so that the token is valid for the
          // rehydrate API calls
          this.store.dispatch(new RehydrateState());
        })
      ),
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LOGIN_FAILURE),
        tap(() => {
          void this.router.navigate(['']);
        })
      ),
    { dispatch: false }
  );

  checkLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CHECK_LOGIN),
      exhaustMap(() =>
        this.auth.checkLogin().pipe(
          take(1),
          map((res: User) => new AuthActions.CheckLoginSuccess(res)),
          catchError((error) => of(new AuthActions.CheckLoginFailure(error))),
          finalize(() => this.store.dispatch(new AuthActions.CheckLoginComplete()))
        )
      )
    )
  );

  checkLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.CHECK_LOGIN_SUCCESS),
        tap(() => {
          // Rehydrate when login is complete so that the token is valid for the
          // rehydrate API calls
          this.store.dispatch(new RehydrateState());
        })
      ),
    { dispatch: false }
  );

  checkFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.CHECK_LOGIN_FAILURE),
        tap((error: any) => {
          if (error.payload !== 'unauthenticated') {
            this.auth.logout();
            this.auth.removeAuth0OrgCookies();

            // Re-Init and login needs to be done in next Angular update cycle,
            // not at once
            setTimeout(() => {
              this.auth.init();
              this.auth.login();
            });
          }
        })
      ),
    { dispatch: false }
  );

  /*
  @Effect()
  loginCallback$ = this.actions$.pipe(
    ofType(AuthActions.LOGIN_CALLBACK),
    switchMap(() => {
      return this.auth.handleCallback();

    }),
    switchMap((res) => [
      new AuthActions.GetUser()
    ]),
    switchMap((res) => [
      new AuthActions.LoginSuccess({
        user: res[0],
        authenticated: res[1]
      })
    ]),
    finalize(() => this.store.dispatch(new AuthActions.LoginComplete()))
  ); */

  loginCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN_CALLBACK),
      exhaustMap((em) =>
        this.auth.handleCallback().pipe(
          take(1),
          map(
            (res) =>
              new AuthActions.LoginSuccess({
                user: res[0],
                authenticated: res[1],
              })
          )
        )
      ),
      catchError((error) => of(new AuthActions.LoginFailure(error))),
      finalize(() => this.store.dispatch(new AuthActions.LoginComplete()))
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LOGOUT),
        tap(() => {
          localStorage.clear();
          this.auth.logout();
        })
      ),
    { dispatch: false }
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.GET_USER),
      exhaustMap((action: AuthActions.GetUser) =>
        this.auth.getUserNoCache$(action.auth0Id).pipe(
          map((user) => new AuthActions.GetUserSuccess(user, action.setNewCurrentOrg)),
          catchError((error) => of(new AuthActions.GetUserFailure(error))),
          finalize(() => {
            this.store.dispatch(new SetOrganisationChanging(false));
            return this.store.dispatch(new AuthActions.GetUserComplete());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private auth: AuthServiceWrapper,
    private maintenanceTextService: MaintenanceTextService,
    private router: Router,
    private store: Store<RootState.IState>
  ) {}
}
