/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{html,ts}', './node_modules/cde-fe-*/**/*.{html,ts,mjs,js}'],
  theme: {
    screens: {
      'xs': { max: '599px' },
      // => @media (max-width: 599px)'

      'sm': { min: '600px', max: '959px' },
      // => @media (min-width: 600px and max-width: 959px) { ... }

      'md': { min: '960px', max: '1279px' },
      // => @media (min-width: 1024px and max-width: 1279px) { ... }

      'lg': { min: '1280px', max: '1919px' },
      // => @media (min-width: 1280px and max-width: 1919px) { ... }

      'xl': { min: '1920px', max: '5000px' },
      // => @media (min-width: 1920px and max-width: 5000px) { ... }

      'lt-xl': { max: '1919px' },
      // => @media (max-width: 1919px) { ... }

      'lt-lg': { max: '1279px' },
      // => @media (max-width: 1279px) { ... }

      'lt-md': { max: '959px' },
      // => @media (max-width: 959px) { ... }

      'lt-sm': { max: '599px' },
      // => @media (max-width: 599px) { ... }

      'gt-xs': '600px',
      // => @media (min-width: 600px) { ... }

      'gt-sm': '960px',
      // => @media (min-width: 960px) { ... }

      'gt-md': '1280px',
      // => @media (min-width: 1280px) { ... }

      'gt-lg': '1920px',
      // => @media (min-width: 1920px) { ... }
    },
  },
  plugins: [],
};
