import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ILastMissedMaintenance, IMachineMaintenance, ILastPerformedMaintenance, INextMaintenance } from '../models/machines.model';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';

export const MAINTENANCE_URL = 'v1/equipment';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private maintenanceUrl = `${environment.apiBaseUrl}/${MAINTENANCE_URL}`;
  constructor(private serviceCall: ServiceCall) {}

  getMaintenence(machineUuid: string, operatingHours: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances?operatingHours=${operatingHours}`)
      .pipe(map((response) => response.data as IMachineMaintenance));
  }

  getNextMaintenence(machineUuid: string, operatingHours: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/next?operatingHours=${operatingHours}`)
      .pipe(map((response) => response.data as INextMaintenance));
  }

  getLastMissedMaintenence(machineUuid: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/missed`)
      .pipe(map((response) => response.data as ILastMissedMaintenance));
  }

  getLastPerformedMaintenence(machineUuid: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/last`)
      .pipe(map((response) => response.data as ILastPerformedMaintenance));
  }
}
