import { Action } from '@ngrx/store';
import { StateError } from '..';
import { CDESyncCouplings } from '@app/shared/models/cde-sync-couplings.model';

export const COUPLINGS = '[Couplings] Get Copulings';
export const COUPLINGS_SUCCESS = '[Couplings] Get Copulings Success';
export const COUPLINGS_FAILURE = '[Couplings] Get Copulings Failure';
export const COUPLINGS_COMPLETE = '[Couplings] Get Copulings Complete';

export const POST_COUPLINGS = '[Couplings] Create Copuling';
export const POST_COUPLINGS_SUCCESS = '[Couplings] Create Copuling Success';
export const POST_COUPLINGS_FAILURE = '[Couplings] Create Copuling Failure';
export const POST_COUPLINGS_COMPLETE = '[Couplings] Create Copuling Complete';

export const DELETE_COUPLINGS = '[Couplings] Delete Copuling';
export const DELETE_COUPLINGS_SUCCESS = '[Couplings] Delete Copuling Success';
export const DELETE_COUPLINGS_FAILURE = '[Couplings] Delete Copuling Failure';
export const DELETE_COUPLINGS_COMPLETE = '[Couplings] Delete Copuling Complete';

export const RESET_COUPLINGS = '[Couplings] Reset Couplings';

export class CouplingsList implements Action {
  readonly type = COUPLINGS;
  // payload is time zone
  constructor(public payload: string) {}
}
export class CouplingsSuccess implements Action {
  readonly type = COUPLINGS_SUCCESS;
  constructor(public payload: CDESyncCouplings[]) {}
}
export class CouplingsFailure implements Action {
  readonly type = COUPLINGS_FAILURE;
  constructor(public payload: StateError) {}
}
export class CouplingsComplete implements Action {
  readonly type = COUPLINGS_COMPLETE;
}

export class PostCouplings implements Action {
  readonly type = POST_COUPLINGS;
  constructor(public payload: string) {}
}
export class PostCouplingsSuccess implements Action {
  readonly type = POST_COUPLINGS_SUCCESS;
}
export class PostCouplingsFailure implements Action {
  readonly type = POST_COUPLINGS_FAILURE;
  constructor(public payload: StateError) {}
}
export class PostCouplingsComplete implements Action {
  readonly type = POST_COUPLINGS_COMPLETE;
}

export class DeleteCouplings implements Action {
  readonly type = DELETE_COUPLINGS;
  constructor(public payload: string) {}
}
export class DeleteCouplingsSuccess implements Action {
  readonly type = DELETE_COUPLINGS_SUCCESS;
}
export class DeleteCouplingsFailure implements Action {
  readonly type = DELETE_COUPLINGS_FAILURE;
  constructor(public payload: StateError) {}
}
export class DeleteCouplingsComplete implements Action {
  readonly type = DELETE_COUPLINGS_COMPLETE;
}

export class ResetCouplings implements Action {
  readonly type = RESET_COUPLINGS;
}

export type Actions =
  | CouplingsList
  | CouplingsSuccess
  | CouplingsFailure
  | CouplingsComplete
  | PostCouplings
  | PostCouplingsSuccess
  | PostCouplingsFailure
  | PostCouplingsComplete
  | DeleteCouplings
  | DeleteCouplingsSuccess
  | DeleteCouplingsFailure
  | DeleteCouplingsComplete
  | ResetCouplings;
