import { Injectable } from '@angular/core';

import { ServiceCall } from './service-call.service';
import { map } from 'rxjs';
import { IAppMetaDataFieldUpdate, IUserAddress } from '../models/user.model';
import { environment } from '@environments/environment';
import { IUser } from 'cde-fe-organization-registration-dialog';

@Injectable()
export class UserService {
  private userServiceUrl = `${environment.claasIdUserApiBaseUrl}`;

  constructor(private serviceCall: ServiceCall) {}

  getUserAddress() {
    return this.serviceCall
      .get(`${this.userServiceUrl}/v3/address`)
      .pipe(
        map((response) =>
          response.data as IUserAddress[]
        )
      );
  }

  updateUserAppMetadata(updateData: IAppMetaDataFieldUpdate) {
    return this.serviceCall
      .patch(`${this.userServiceUrl}/v3/users/app-metadata-field`, updateData)
      .pipe(
        map((response) =>
          response.data as IUser
        )
      );
  }
}
