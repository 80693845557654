import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, filter, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  observeKey(key: string): Observable<any> {
    const initialValue = JSON.parse(localStorage.getItem(key)!);

    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event) => event.storageArea === localStorage),
      filter((event) => event.key === key),
      map((event) => JSON.parse(event.newValue!)),
      startWith(initialValue)
    );
  }

  setItem(key: string, value: any): void {
    const oldValue = localStorage.getItem(key);
    const newValue = JSON.stringify(value);

    localStorage.removeItem(key);
    localStorage.setItem(key, newValue);

    window.dispatchEvent(
      new StorageEvent('storage', {
        key,
        newValue: newValue,
        oldValue: oldValue,
        storageArea: localStorage,
        url: window.location.href,
      })
    );
  }

  getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key)!);
  }

  removeItem(key: string): void {
    const oldValue = localStorage.getItem(key);
    localStorage.removeItem(key);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key,
        newValue: null,
        oldValue: oldValue,
        storageArea: localStorage,
        url: window.location.href,
      })
    );
  }
}
