import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { ServiceCall } from './service-call.service';
import { map } from 'rxjs/operators';
import { IFeatureToggle } from '../models/feature-toggle.model';
import { environment } from '@environments/environment';

export const FEATURE_TOGGLE_URL = 'v1/feature-toggles';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {

  private featureToggleUrl = `${environment.apiBaseUrl}/${FEATURE_TOGGLE_URL}`;
  constructor(private serviceCall: ServiceCall) {}

  getFeatureToggles(): Observable<IFeatureToggle[]> {
    return this.serviceCall.get(`${this.featureToggleUrl}`).pipe(map((response) => response.data));
  }
}
