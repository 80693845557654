import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceCall } from './service-call.service';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private sc: ServiceCall,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: number | string
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    retries = 0
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // if (err.status === 0 || err.status >= 500) {
        //   if (retries === 3) {
        //     this.router.navigateByUrl('/server-error');
        //   } else {
        //     return this.intercept(request, next, ++retries);
        //   }
        // }

        // const error = err.error.message || err.statusText;
        const exception = throwError(err);
        return exception;
      })
    );
  }
}
