import { AuthServiceWrapper } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as RootState from '../state';
import * as AuthActions from '../state/auth/auth.actions';
import { environment } from '@environments/environment';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private cache = new Map<string, Subject<void>>();

  constructor(private auth: AuthServiceWrapper, private store: Store<RootState.IState>, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.includes(environment.apiBaseUrl) ||
      req.url.includes(environment.claasIdApiBaseUrl) ||
      req.url.includes(environment.claasIdUserApiBaseUrl)
    ) {
      const localStorageOrgId = window.localStorage.getItem('currentOrganisationId');
      const redirectPath = `${window.location.origin}/callback`;
      const options: GetTokenSilentlyOptions = {
        authorizationParams: {
          audience: environment.apiAudience,
          ...(localStorageOrgId && { organization: localStorageOrgId }),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          redirect_uri: redirectPath,
        },
      };
      return this.auth.getTokenSilently$(options).pipe(
        mergeMap((token: string) => this.createTokenReq(token, req, next)),
        catchError(error => throwError(() => {
          console.error('Interceptor error', error);
          const errorData = error.error;
          const errorText = typeof errorData === 'string' ? (errorData as string)?.toLowerCase() : errorData.error;
          const errorDescription = typeof error.error_description === 'string' ? (error.error_description as string)?.toLowerCase() : null;

          // as seen in #191740 it can occur that an old organization is set in
          // the local storage which then leads to an authorize call using an
          // invalid organisation for the currently logged in user. In that case
          // the user session needs to be refreshed by a re-login
          if (
            (errorText === 'invalid_request' && errorDescription?.includes('parameter organization is invalid')) ||
            (errorText === 'login_required') ||
            (typeof errorData === 'string' ? errorText.includes('login required') : false)
          ) {
            const url = this.router.url;
             // call of localStorage.setItem('redirectUrl', url); should not be
             // neccessary because this is done in the auth effects

            // when callback is current route do not relogin
            if (!url.startsWith('/callback?code=')) {
              this.relogin();
            }
          }

          if (errorDescription != null && errorDescription.length > 0) {
            return new Error(errorDescription);
          }

          if (error?.data?.message != null) {
            return new Error(error?.data?.message);
          }

          return errorData;
        }))
      );
    } else {
      req = req.clone({ setHeaders: {} });
      return this.handleRequest(req, next);
    }
  }

  private createTokenReq(token: string, req: HttpRequest<any>, next: HttpHandler) {
    this.store.dispatch(new AuthActions.SetToken(token));
    const tokenReq = req.clone({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      setHeaders: { Authorization: `Bearer ${token}` },
    });
    return this.handleRequest(tokenReq, next);
  }

  /**
   * Wrapper to handle requests in order to cancel repeated requests
   * @param request the incoming request
   * @param next the httpHandler
   * @returns the obervable http event
   */
  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if you want to check params as well then use request.urlWithParams.
    const url = request.url;
    if (
      url.includes('/stock-images') ||
      url.includes('/image') ||
      url.includes('/individual-images') ||
      url.includes('/weather-information') ||
      (url.includes('/dashboards') && url.includes('fromOrgReg=1')) ||
      url.includes('/inbox/files') ||
      url.includes('/notifications') ||
      (url.includes('/dealers/') && url.match(/\d{10}$/))
    ) {
      return next.handle(request);
    }
    // check if the request is already cached
    const cachedResponse = this.cache.get(url);
    // cancel any previous requests
    if (cachedResponse) {
      return EMPTY;
    }
    const cancelRequests$ = new Subject<void>();
    // cache the new request , so that we can cancel it if needed.
    this.cache.set(url, cancelRequests$);
    const newRequest = next.handle(request).pipe(
      // complete the subject when the request completes.
      finalize(() => this.cache.delete(url))
    );
    return newRequest;
  }

  private relogin() {
    this.cache.clear();
    this.auth.logout();
    this.auth.removeAuth0OrgCookies();

    // Re-Init and login needs to be done in next Angular update cycle,
    // not at once
    setTimeout(() => {
      this.auth.init();
      this.auth.login();
    });
  }
}
