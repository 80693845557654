import { StateError } from '..';
import * as CouplingsActions from './couplings.actions';
import { CDESyncCouplings } from '@app/shared/models/cde-sync-couplings.model';

export interface State {
  couplings: CDESyncCouplings[];
  listPending: boolean;
  postPending: boolean;
  deletePending: boolean;
  error: StateError;
}

export const initialState: State = {
  couplings: [],
  listPending: false,
  postPending: false,
  deletePending: false,
  error: null,
};

export function reducer(state = initialState, action: CouplingsActions.Actions): State {
  switch (action.type) {
    case CouplingsActions.COUPLINGS: {
      return {
        ...state,
        listPending: true,
      };
    }

    case CouplingsActions.COUPLINGS_SUCCESS: {
      return {
        ...state,
        couplings: action.payload,
      };
    }

    case CouplingsActions.COUPLINGS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case CouplingsActions.COUPLINGS_COMPLETE: {
      return {
        ...state,
        listPending: false,
      };
    }

    case CouplingsActions.POST_COUPLINGS: {
      return {
        ...state,
        postPending: true,
      };
    }

    case CouplingsActions.POST_COUPLINGS_SUCCESS: {
      return {
        ...state,
      };
    }

    case CouplingsActions.POST_COUPLINGS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case CouplingsActions.POST_COUPLINGS_COMPLETE: {
      return {
        ...state,
        postPending: false,
      };
    }

    case CouplingsActions.DELETE_COUPLINGS: {
      return {
        ...state,
        deletePending: true,
      };
    }

    case CouplingsActions.DELETE_COUPLINGS_SUCCESS: {
      return {
        ...state,
      };
    }

    case CouplingsActions.DELETE_COUPLINGS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case CouplingsActions.DELETE_COUPLINGS_COMPLETE: {
      return {
        ...state,
        deletePending: false,
      };
    }

    case CouplingsActions.RESET_COUPLINGS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getCouplings = (state: State) => state.couplings;
