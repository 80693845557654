import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigurationsService } from '@app/shared/services/configurations.service';
import * as ConfigurationsActions from './configs.actions';
import * as RootState from '..';
import { of, Subscription } from 'rxjs';
import { IConfigurations, IConfigurationsListRequest, ICreateOfferDemo, IOffer } from '@app/shared/models/configurations.model';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { ToasterType } from '@app/cde-toast/utils/toastr-options';
@Injectable()
export class ConfigurationsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.CONFIGURATIONS),
      map((action: ConfigurationsActions.ConfigurationsList) => action.payload),
      switchMap((payload: IConfigurationsListRequest) =>
        this.configsService.getAllConfigurations(payload.language, payload.timeZone).pipe(
          take(1),
          map((configMachines: IConfigurations) => new ConfigurationsActions.ConfigurationsSuccess(configMachines.content)),
          catchError((error) => {
            if (error.failCode !== 0 && error.failCode < 500) {
              this.alertService.showError(
                this.translate.instant('configuration.failed_to_load_config'),
                this.translate.instant('alert_message.network_error'),
                'alert-error-icon',
                this.alertOptions
              );
            }
            return of(new ConfigurationsActions.ConfigurationsFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new ConfigurationsActions.ConfigurationsComplete());
          })
        )
      )
    )
  );

  add$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.ADD_CONFIGURATION),
        map((action: ConfigurationsActions.AddConfiguration) => action.payload),
        switchMap((payload: { name: string; code: string; language: string; timeZone: string }) =>
          this.configsService.addMachineConfigs(payload.name, payload.code, payload.language, payload.timeZone).pipe(
            take(1),
            map((newConfig: IConfigurations) => {
              if (newConfig) {
                this.configsService.newConfigSubject.next(newConfig);
              }
            }),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('configuration.could_not_add_config'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              this.configsService.newConfigSubject.next(null);
              return of(new ConfigurationsActions.AddConfigurationFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new ConfigurationsActions.AddConfigurationComplete());
            })
          )
        )
      ),
    { dispatch: false }
  );

  // UpdateConfiguration
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.UPDATE_CONFIGURATION),
      map((action: ConfigurationsActions.UpdateConfiguration) => action.payload),
      switchMap(
        (payload: {
          machineConfigurationId: string;
          code: string;
          language: string;
          isShared: boolean;
          modalName: string;
          timeZone: string;
        }) =>
          this.configsService
            .overwriteMachineConfigs(payload.machineConfigurationId, payload.code, payload.language, payload.timeZone, payload.isShared)
            .pipe(
              take(1),
              map((config: IConfigurations) => {
                this.alertService.callSnackbarSuccess(
                  this.translate.instant('configuration.config_update_success'),
                  '',
                  ToasterType.snackbarSuccess, // custom attribute
                  {}
                );
                return new ConfigurationsActions.UpdateConfigurationSuccess(config);
              }),
              catchError((error) => {
                if (payload.isShared) {
                  this.alertService.showError(
                    this.translate.instant('configuration.could_not_share_config'),
                    this.translate.instant('alert_message.try_again'),
                    'alert-error-icon',
                    this.alertOptions
                  );
                } else {
                  this.alertService.showError(
                    this.translate.instant('configuration.could_not_stop_share_config'),
                    this.translate.instant('alert_message.try_again'),
                    'alert-error-icon',
                    this.alertOptions
                  );
                }

                return of(new ConfigurationsActions.UpdateConfigurationFailure(error));
              }),
              finalize(() => {
                this.store.dispatch(new ConfigurationsActions.UpdateConfigurationComplete());
              })
            )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.DELETE_CONFIGURATION),
      map((action: ConfigurationsActions.DeleteConfiguration) => action.payload),
      switchMap((payload: { machineConfigurationId: string }) =>
        this.configsService.deleteConfigurationById(payload.machineConfigurationId).pipe(
          take(1),
          map(() => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('configuration.config_delete_success'),
              '',
              ToasterType.snackbarSuccess, // custom attribute
              {}
            );
            return new ConfigurationsActions.DeleteConfigurationSuccess({
              machineConfigurationId: payload.machineConfigurationId,
            });
          }),
          catchError((error) => {
            this.store.dispatch(new ConfigurationsActions.HandleConfigurationLoader(false));
            this.alertService.showError(
              this.translate.instant('configuration.could_not_delete_config'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConfigurationsActions.DeleteConfigurationFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new ConfigurationsActions.DeleteConfigurationComplete());
          })
        )
      )
    )
  );

  createOfferRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.CREATE_OFFER_REQUEST),
      map((action: ConfigurationsActions.CreateOfferRequest) => action.request),
      switchMap((request: ICreateOfferDemo) =>
        this.configsService.createOfferRequest(request).pipe(
          map(
            (response: IOffer) =>
              new ConfigurationsActions.CreateOfferRequestSuccess({ response, configurationId: request.configurationId })
          ),
          catchError((error) => of(new ConfigurationsActions.CreateOfferRequestFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConfigurationsActions.CreateOfferRequestComplete());
          })
        )
      )
    )
  );

  createOfferRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.CREATE_OFFER_REQUEST_SUCCESS),
        tap(() => {
          this.alertService.callSnackbarSuccess(
            this.translate.instant('configuration.offerRequest.createSuccess'),
            '',
            ToasterType.snackbarSuccess
          );
        })
      ),
    { dispatch: false }
  );

  createOfferRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.CREATE_OFFER_REQUEST_FAILURE),
        tap(() => {
          this.alertService.showError(
            this.translate.instant('configuration.offerRequest.createFailure'),
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
        })
      ),
    { dispatch: false }
  );

  createDemoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.CREATE_DEMO_REQUEST),
      map((action: ConfigurationsActions.CreateDemoRequest) => action.request),
      switchMap((request: ICreateOfferDemo) =>
        this.configsService.createDemoRequest(request).pipe(
          map(
            (response: IOffer) => new ConfigurationsActions.CreateDemoRequestSuccess({ response, configurationId: request.configurationId })
          ),
          catchError((error) => of(new ConfigurationsActions.CreateDemoRequestFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConfigurationsActions.CreateDemoRequestComplete());
          })
        )
      )
    )
  );

  createDemoRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.CREATE_DEMO_REQUEST_SUCCESS),
        tap(() => {
          this.alertService.callSnackbarSuccess(
            this.translate.instant('configuration.offerRequest.createSuccess'),
            '',
            ToasterType.snackbarSuccess
          );
        })
      ),
    { dispatch: false }
  );

  createDemoRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.CREATE_DEMO_REQUEST_FAILURE),
        tap(() => {
          this.alertService.showError(
            this.translate.instant('configuration.offerRequest.createFailure'),
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
        })
      ),
    { dispatch: false }
  );

  downloadQuoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationsActions.DOWNLOAD_QUOTE_REQUEST),
      map((action: ConfigurationsActions.DownloadQuoteRequest) => action.payload),
      switchMap((payload: { machineConfigurationId: string; dealerId: string }) =>
        this.configsService.getOfferPDF(payload.dealerId, payload.machineConfigurationId).pipe(
          map(
            (response: Blob) =>
              new ConfigurationsActions.DownloadQuoteRequestSuccess({
                machineConfigurationId: payload.machineConfigurationId,
                dealerId: payload.dealerId,
                pdf: response,
              })
          ),
          catchError((error) => of(new ConfigurationsActions.DownloadQuoteRequestFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConfigurationsActions.DownloadQuoteRequestComplete());
          })
        )
      )
    )
  );

  downloadQuoteRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConfigurationsActions.DOWNLOAD_QUOTE_REQUEST_SUCCESS),
        map((action: ConfigurationsActions.DownloadQuoteRequestSuccess) => action.payload),
        tap((data) => {
          if (data.pdf && data.pdf.size > 0) {
            const fileURL = URL.createObjectURL(data.pdf);
            window.open(fileURL);
          }
        })
      ),
    { dispatch: false }
  );

  private alertOptions = TOASTR_ALERT_OPTIONS;

  constructor(
    private translate: TranslateService,
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private configsService: ConfigurationsService,
    private alertService: AlertService,
  ) {}

  showSnackbarSuccess(message: string = '', action = false, option = {}): void {
    this.alertService.callSnackbarSuccess(
      this.translate.instant('alert_message.success'),
      '',
      ToasterType.snackbarSuccess, // custom attribute
      option
    );
  }
}
