import { APP_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './shared/services/error.interceptor';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './shared/core.module';
import { CdeSidenavModule } from './shared/components/cde-sidenav/cde-sidenav.module';
import { AppComponent } from './app.component';

// services
import { AnalyticsService } from './shared/services/analytics.service';
import { AuthServiceWrapper } from './shared/services/auth.service';
import { DashboardService } from './shared/services/dashboard.service';
import { MachinesService } from './shared/services/machines.service';
import { NotificationsService } from './shared/services/notifications.service';
import { ServiceCall } from './shared/services/service-call.service';
import { SupportService } from './shared/services/support.service';
import { UserService } from './shared/services/user.service';

// LOCALES based on languages available in CLAAS ID
import localeCroatia from '@angular/common/locales/hr';
import localeCzech from '@angular/common/locales/cs';
import localeDenmark from '@angular/common/locales/da';
import localeDutch from '@angular/common/locales/nl';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEstonia from '@angular/common/locales/et';
import localeFinland from '@angular/common/locales/fi';
import localeFrance from '@angular/common/locales/fr';
import localeGermany from '@angular/common/locales/de';
import localeItaly from '@angular/common/locales/it';
import localeLatvia from '@angular/common/locales/lv';
import localeLithuania from '@angular/common/locales/lt';
import localeNorway from '@angular/common/locales/nb'; // claas id returns 'no'
import localePoland from '@angular/common/locales/pl';
import localePortugal from '@angular/common/locales/pt';
import localeRomania from '@angular/common/locales/ro';
import localeRussian from '@angular/common/locales/ru';
import localeSlovakia from '@angular/common/locales/sk';
import localeSlovenia from '@angular/common/locales/sl';
import localeSpain from '@angular/common/locales/es';
import localeSweden from '@angular/common/locales/sv';
import localeUkraine from '@angular/common/locales/uk';
import localeHungary from '@angular/common/locales/hu';
import localeKazakhstan from '@angular/common/locales/kk';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdeToastModule } from './cde-toast/cde-toast.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotFoundModule } from './not-found/not-found.module';
import { CdeFeConvertPipeModule, TRANSLATE_SERVICE } from 'cde-fe-convert-pipe';
import { environment } from '@environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocalizedPaginator } from './shared/utils/localized-paginator';
import { LegalTextsService } from './shared/services/legal-texts.service';
import { SpinnerModule } from './spinner/spinner.module';
import { OperationsService } from './shared/services/operations.service';
import { FileTransferService } from './shared/services/file-transfer.service';
import { RoutingStateService } from './shared/services/routing-state.service';
import { LicenseService } from './shared/services/license.service';
// import this here so that the DateAdapter is created once as a singleton which
// is used in all child modules
import { MatNativeDateModule } from '@angular/material/core';

export function createTranslateLoader(client: HttpClient) {
  // return new TranslateHttpLoader(client, 'https://claasconnectassets.blob.core.windows.net/translations/', '.json');
  return new TranslateHttpLoader(client, environment.translationBaseUrl, '.json');
}

registerLocaleData(localeCroatia);
registerLocaleData(localeCzech);
registerLocaleData(localeDenmark);
registerLocaleData(localeDutch);
registerLocaleData(localeEnGb);
registerLocaleData(localeEstonia);
registerLocaleData(localeFinland);
registerLocaleData(localeFrance);
registerLocaleData(localeGermany);
registerLocaleData(localeItaly);
registerLocaleData(localeLatvia);
registerLocaleData(localeLithuania);
registerLocaleData(localeNorway);
registerLocaleData(localePoland);
registerLocaleData(localePortugal);
registerLocaleData(localeRomania);
registerLocaleData(localeRussian);
registerLocaleData(localeSlovakia);
registerLocaleData(localeSlovenia);
registerLocaleData(localeSpain);
registerLocaleData(localeSweden);
registerLocaleData(localeUkraine);
registerLocaleData(localeHungary);
registerLocaleData(localeKazakhstan);

const PROVIDERS = [
  // SERVICES
  AnalyticsService,
  AuthServiceWrapper,
  DashboardService,
  FileTransferService,
  LegalTextsService,
  MachinesService,
  NotificationsService,
  LicenseService,
  OperationsService,
  ServiceCall,
  SupportService,
  UserService,
  RoutingStateService,
  { provide: localeCroatia, useValue: 'hr' },
  { provide: localeCzech, useValue: 'cs' },
  { provide: localeDenmark, useValue: 'da' },
  { provide: localeDutch, useValue: 'nl' },
  { provide: localeEnGb, useValue: 'en-GB' },
  { provide: localeEstonia, useValue: 'et' },
  { provide: localeFinland, useValue: 'fi' },
  { provide: localeFrance, useValue: 'fr' },
  { provide: localeGermany, useValue: 'de' },
  { provide: localeItaly, useValue: 'it' },
  { provide: localeLatvia, useValue: 'lv' },
  { provide: localeLithuania, useValue: 'lt' },
  { provide: localeNorway, useValue: 'no' },
  { provide: localePoland, useValue: 'pl' },
  { provide: localePortugal, useValue: 'pt' },
  { provide: localeRomania, useValue: 'ro' },
  { provide: localeRussian, useValue: 'ru' },
  { provide: localeSlovakia, useValue: 'sk' },
  { provide: localeSlovakia, useValue: 'sl' },
  { provide: localeSpain, useValue: 'es' },
  { provide: localeSweden, useValue: 'sv' },
  { provide: localeUkraine, useValue: 'uk' },
  { provide: localeHungary, useValue: 'hu' },
  { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  { provide: TRANSLATE_SERVICE, useClass: TranslateService },
  { provide: MatPaginatorIntl, useClass: LocalizedPaginator },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-gb',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CdeSidenavModule,
    CdeToastModule,
    CoreModule,
    NotFoundModule,
    SharedModule,
    SpinnerModule,
    CdeFeConvertPipeModule.forRoot({}),
    MatNativeDateModule,
  ],
  exports: [CdeFeConvertPipeModule],
  providers: [
    PROVIDERS,
    MatIconRegistry,
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: TRANSLATE_SERVICE, useExisting: TranslateService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
