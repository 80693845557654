import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  finalize,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CouplingsService } from '@app/shared/services/couplings.service';
import { MachinesService } from '@app/shared/services/machines.service';
import * as CouplingsActions from './couplings.actions';
import * as RootState from '..';
import { of, Subscription } from 'rxjs';
import {CDESyncCouplings} from '@app/shared/models/cde-sync-couplings.model';
@Injectable()
export class CouplingsEffects {
  timeZoneWatcher: Subscription;

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouplingsActions.COUPLINGS),
      map((action: CouplingsActions.CouplingsList) => action.payload),
      switchMap((timeZone) =>
        this.couplingsService.getCouplings(timeZone).pipe(
          take(1),
          map((info: CDESyncCouplings) => {
            const couplings = Object.values(info);
            return new CouplingsActions.CouplingsSuccess(couplings);
          }),
          catchError((error) =>
            of(new CouplingsActions.CouplingsFailure(error))
          ),
          finalize(() => {
            this.store.dispatch(new CouplingsActions.CouplingsComplete());
          })
        )
      )
    )
  );

  post$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouplingsActions.POST_COUPLINGS),
      map((action: CouplingsActions.PostCouplings) => action.payload),
      switchMap((partnerId) =>
        this.couplingsService.postCouplings(partnerId).pipe(
          take(1),
          map((info: CDESyncCouplings) => {
            if (info && info.acceptorUrl) {
              if (isPlatformBrowser(this.platformId)) {
                // Open 365 app in the current tab
                window.location.href = info.acceptorUrl;
              }
            }
            return new CouplingsActions.PostCouplingsSuccess();
          }),
          catchError((error) =>
            of(new CouplingsActions.PostCouplingsFailure(error))
          ),
          finalize(() => {
            this.store.dispatch(new CouplingsActions.PostCouplingsComplete());
          })
        )
      )
    )
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouplingsActions.DELETE_COUPLINGS),
      map((action: CouplingsActions.DeleteCouplings) => action.payload),
      switchMap((couplingId: string) =>
        this.couplingsService.deleteCoupling(couplingId).pipe(
          take(1),
          map(
            (info: CDESyncCouplings) => new CouplingsActions.DeleteCouplingsSuccess()
          ),
          catchError((error) =>
            of(new CouplingsActions.DeleteCouplingsFailure(error))
          ),
          finalize(() => {
            // TODO: This watcher has to be unsubscriped
            this.timeZoneWatcher = this.machinesService.unit$.subscribe(
              (zone: string) => {
                this.store.dispatch(new CouplingsActions.CouplingsList(zone));
              }
            );
            this.store.dispatch(new CouplingsActions.DeleteCouplingsComplete());
          })
        )
      )
    )
  );

  constructor(
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private couplingsService: CouplingsService,
    private machinesService: MachinesService,
    @Inject(PLATFORM_ID) private platformId: number | string
  ) {}
}
