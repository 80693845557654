import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, finalize, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as RootState from '@app/shared/state';
import * as UserActions from '@app/shared/state/user/user.actions';
import * as AuthActions from '@app/shared/state/auth/auth.actions';

import { UserService } from '@app/shared/services/user.service';
import { IAppMetaDataFieldUpdate } from '@app/shared/models/user.model';

@Injectable()
export class UserEffects {
  getUserAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_USER_ADDRESSES),
      mergeMap(() =>
        this.userService.getUserAddress().pipe(
          map((addresses) => new UserActions.GetUserAddressesSuccess(addresses)),
          catchError((error) => of(new UserActions.GetUserAddressesFailure(error))),
          finalize(() => this.store.dispatch(new UserActions.GetUserAddressesComplete()))
        )
      )
    )
  );

  updateUserAppMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UPDATE_USER_APP_METADATA),
      map((action: UserActions.UpdateUserAppMetadata) => action.updateData),
      mergeMap((updateData: IAppMetaDataFieldUpdate) =>
        this.userService.updateUserAppMetadata(updateData).pipe(
          map((user) => new UserActions.UpdateUserAppMetadataSuccess(user)),
          catchError((error) => of(new UserActions.UpdateUserAppMetadataFailure(error))),
          finalize(() => this.store.dispatch(new UserActions.UpdateUserAppMetadataComplete()))
        )
      )
    )
  );

  completeRegistrationSuccessSetMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UPDATE_USER_APP_METADATA_SUCCESS),
      withLatestFrom(this.store),
      map(([action, store]: [UserActions.UpdateUserAppMetadataSuccess, RootState.IState]) => {
        // refresh token/user data after updating the metadata to get the
        // current state
        const orgState = store.organisations;
        const currentOrgId = orgState.lastSuccess?.auth0Id;
        return new AuthActions.GetUser(currentOrgId);
      })
    )
  );

  constructor(
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private userService: UserService
  ) {}
}
